@import '../partials/variables';

.header {
  background-color: $white;
  position: relative;
  overflow: visible;
  font-size: 14px;

  padding: 20px 0;
  color: $blue;
  height: 90px;

  .logo-wrapper img {
    margin: -15px 0 -15px;
    width: 165px;

    @include max-device('tablet') {
      width: 105px;
    }
  }

  .logo-link {
    @include LibreMedium;
    color: $gold;
    margin-left: 25px;
    letter-spacing: 2px;
  }

  .header-cnt,
  .menu-link-wrapper,
  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .local-wrapper {
    display: none;

    .local-site {
      margin-right: 10px;
    }

    @include max-device('tablet') {
      margin-top: 20px;
    }
  }

  &.homepage {
    .local-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &.light {
    .hamburger,
    .menu-link-wrapper {
      display: none;
    }
  }

  .hamburger {
    display: none;

    @include max-device('tablet') {
      display: block;
    }
  }

  .user {
    background: $blue;
    border-radius: 10px;
    padding: 12px 20px;
    color: $white;

    &:hover {
      .inner-menu {
        display: block;
      }
    }

    .inner-menu {
      display: none;
      width: 100%;
      position: absolute;
      left: 0;
      margin-top: 5px;
      top: 100%;
      padding: 10px;
      z-index: 10;
      background: $blue;
      text-align: center;
      border-radius: 10px;

      &:before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        top: -30px;
        left: 0;
        height: 30px;
      }

      a {
        border-bottom: 1px solid $white;
        text-decoration: none;
        padding: 5px 0 15px;
        margin: 0 auto 10px;
        cursor: pointer;
        display: block;
        color: $white !important;
        width: 90%;

        &:last-child {
          padding-bottom: 0;
          border: none;
        }
      }
    }
  }

  .lang {
    display: flex;
    align-items: center;

    &:after {
      content: '';
      width: 12px;
      height: 8px;
      margin-left: 5px;
      background: url(/fe-web/img/icons/arrow-down-blue.svg) no-repeat center center;
    }

    &:hover {
      .inner-menu {
        display: block;
      }
    }

    .inner-menu {
      display: none;
      width: 150px;
      position: absolute;
      left: 50%;
      margin-left: -75px;
      top: 20px;
      border: 1px solid black;
      padding: 4px 10px;
      z-index: 10;
      background: $white;
      text-align: center;
      border-radius: 10px;

      &:before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        top: -30px;
        left: 0;
        height: 30px;
      }

      .alt-locale {
        border-bottom: 1px solid $blue;
        text-decoration: none;
        cursor: pointer;
        display: block;
        padding: 8px 0;
        color: $blue;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .contact {
    text-decoration: none;
    align-items: center;
    display: flex;
    color: $blue;

    &:before {
      content: '';
      width: 15px;
      height: 14px;
      margin-right: 5px;
      background: url(/fe-web/img/icons/contact.svg) no-repeat center center;
    }
  }
  @include max-device('tablet') {
    &.open {
      .menu-link-wrapper {
        padding: 0 0 15px;
        display: flex;
      }

      .hamburger {
        transform: rotate(90deg);
      }
    }
    .logo-wrapper img {
      margin: 0;
    }
    .menu-link-wrapper {
      flex-direction: column-reverse;
      position: absolute;
      background: $white;
      display: none;
      z-index: 100;
      width: 100%;
      top: 100%;
      left: 0;

      .lang {
        width: 80%;
        text-align: center;
        display: block;
        border: 2px solid $blue;
        border-radius: 10px;
        padding: 10px;
        margin: 10px auto;
        font-size: 0;
        line-height: 0;

        .inner-menu {
          margin: -28px 0 12px;
          position: relative;
          display: block;
          border: none;
          width: 100%;
          left: 0;

          &:before {
            display: none;
          }

          .alt-locale {
            line-height: 14px;
            font-size: 14px;
          }
        }
      }

      .contact {
        border: 2px solid $blue;
        border-radius: 10px;
        padding: 10px 10px;
        width: 80%;
        margin: 10px auto;
        text-align: center;
        display: block;
        line-height: 16px;
      }

      .user {
        width: 80%;
        text-align: center;
        margin: 10px auto;

        .inner-menu {
          display: block;
          position: relative;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}
