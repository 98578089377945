@import "partials/_variables";

.screenset-wrapper {
  &:after {
    display: block;
    clear: both;
    float: none;
    content: "";
  }

  .image {
    background-image: url("/fe-web/img/bg/screenset.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 818px;
    float: left;
    width: 40%;

    @include max-device('tablet') {
      min-height: 34vh;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  #screensets-cnt {
    float: left;
    width: 60%;

    @include max-device('tablet') {
      width: 100%;
    }
  }

  .register {
    @include LibreRegular;
    text-align: center;
    line-height: 12px;
    margin: 20px auto 0;
    color: $dark-gray;
    font-size: 12px;
    float: right;
    width: 60%;

    a {
      @include LibreBold;
      color: $dark-gray;
      cursor: pointer;
    }

    @include max-device('tablet') {
      margin: 0 auto 20px;
      float: none;
    }
  }

  .alert-info {
    @include genericDialog;
    position: relative;
    text-align: center;
    margin-top: 40px;
    max-width: 45%;
    top: 45px;

    @include max-device('tablet') {
      max-width: 75%;
    }
  }
}