@import '../../partials/variables';
@import '../../partials/mixins';


.offerPremium{

  &__wrapper{
    display: flex;
    flex-direction: column;
    color: $white;
    align-items: center;
    margin: 14px auto 132px;
    padding: 36px 56px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(241, 241, 241, 0.1);
    backdrop-filter: blur(8px);

    @include max-device('tablet') {
      padding: 36px 20px;
      max-width: 350px;
    }
  }


  &__list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 32px;
    @include max-device('tablet') {
      flex-direction: column-reverse;
    }
  }

  &__box{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    @include max-device('medium-desktop') {
      flex-direction: column;
    }
  }

  .card-grid__item{
    width: 375px;


    @include max-device('tablet') {
      width: 315px;
    }

    a{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .card-grid__icon{
      width: 40px;
      margin: 24px 24px auto auto;
    }

   .yxpHtmlTextWrapper{
     height: 148px;

     &:before{
       @include icon-before('offer');
     }
   }
  }

  &__flyer{
    display: flex;
    gap: 15px;
    flex-direction: row;
    @include max-device('desktop-plus') {
      flex-direction: column;
    }
  }

  &__download-flyer{
    background-size: cover;
    width:375px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;

    @include max-device('tablet') {
      width: 315px;
    }
  }

  &__download-flyer:nth-child(odd){
    background: #998242 url("#{$image-path}/bg/bg-download-flyer.png") center center;
  }

  &__download-flyer:nth-child(even){
    background: #1893B9 url("#{$image-path}/bg/bg-download-flyer.png") center center;
  }

  &__download-name{
    //color: $white;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    img{
      width: 24px;
      height: 24px;
    }
  }

}