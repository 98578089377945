@import '../../partials/variables';
@import '../../partials/mixins';


.trainingDetailPremium{

  &__wrapper{
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: $white;
    align-items: center;
    margin: 14px auto 132px;
    padding: 36px 56px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(241, 241, 241, 0.1);
    backdrop-filter: blur(8px);

    @include max-device('tablet') {
      padding: 36px 20px;
      max-width: 350px;
    }
  }

  &__img {
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 70px;
    width: 100%;

    @include max-device('tablet') {
      margin-bottom: 40px;
    }
  }

  &__category{
    text-align: center;
    margin-bottom: 32px;
  }

  &__description{
    @include LibreRegular;
    font-size: 16px;
    line-height: 24px;
  }

  h2{
    text-align: center;
    margin-bottom: 32px;
  }

  &__files-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @include max-device('medium-desktop') {
      justify-content: center;
    }
  }

  &__videos-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @include max-device('medium-desktop') {
      justify-content: center;
    }

    video{
      @include max-device('tablet') {
        width: 315px;
      }
    }
  }

}