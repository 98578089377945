@import '../../partials/variables';
@import '../../partials/mixins';


.card-grid {

  &__wrapper{
    margin-top:24px;
  }

  &__row {
  margin-top: 24px;

    .yxpRow {
      gap: 24px;
      @include max-device('tablet') {
        flex-direction: column;
      }
    }
  }

  .yxpH2 {
    @include LibreBold;
  }

  &__item {
    //width: 624px;
    height: 345px;
    display: flex;
    align-items: end;
    border-radius: 8px;

    a{
      text-decoration: none;
      color: $white;
      width: 100%;
    }

    .yxpRow {
      width: 100%;

      .yxpBlock {
        padding: 0;
      }
    }

    .yxpHtmlTextWrapper {
      border-radius: 0 0 8px 8px;
      background: rgba(0, 0, 0, 0.20);
      backdrop-filter: blur(8px);
      width: 100%;
      padding: 24px 16px 32px 16px;
    }


    .warranty .yxpHtmlTextWrapper:before  {
      @include icon-before('alert')
    }

    .training .yxpHtmlTextWrapper:before  {
      @include icon-before('book')
    }

    .offers .yxpHtmlTextWrapper:before  {
      @include icon-before('offer')
    }

    .assistance .yxpHtmlTextWrapper:before  {
      @include icon-before('document-white')
    }

    &.yxp-cols-6{
      @include max-device('tablet') {
        width: 100%;
      }
    }

  }
    &__title {
      @include LibreBold;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 8px 32px
    }

    &__description {
      @include LibreRegular;
      font-size: 16px;
      line-height: 20px
    }


}