@import 'partials/fonts';
@import 'partials/general';
@import 'partials/custom';
@import 'partials/breadcrumbs';
@import 'partials/premium/breadcrumbsPremium';
@import 'partials/premium/form';
@import 'partials/premium/customPagination';

@import 'components/header';
@import 'components/footer';
@import 'components/cardWide';
@import 'components/bannerFullWidth';
@import 'components/newsAside';
@import 'components/homeCard';
@import 'components/newsDetail';
@import 'components/newsListing';
@import 'components/documentsRepository';
@import 'components/documentsRepositoryDetail';
@import 'components/registration';
@import 'components/customForm';
@import 'components/splashPage';
@import 'components/visual';
@import 'components/imageText';
@import 'components/gallery';
@import "components/premium/headerPremium";
@import "components/premium/visualPremium";
@import "components/premium/cardGrid";
@import "components/premium/footerPremium";
@import 'components/premium/splashPagePremium';
@import 'components/premium/screensetPremium';
@import 'components/premium/newsListingPremium';
@import 'components/premium/newsDetailPremium';
@import 'components/premium/offersListing';
@import 'components/premium/exclusiveOfferDetailPremium';
@import 'components/premium/professionalTraining';
@import 'components/premium/cardIcon';
@import 'components/premium/professionalTrainingDetail';
@import 'components/premium/fileCard';
@import 'components/premium/dashboardPremium';
@import 'components/premium/bannerFullWidthPremium';
@import 'components/premium/profile';
@import 'components/premium/fleet';
@import 'components/premium/fleetCard';
@import 'components/premium/extendedWarranty';
@import 'components/premium/warrantyCard';
@import 'components/premium/contactUs';
@import 'components/premium/communications';
@import 'components/premium/register';
@import 'components/premium/docCatListing';
@import 'components/premium/docCatDetail';



@import 'screenset';
@import 'home';

.page-spacer {
  max-width: 1440px;
  margin: 0 auto;
}

.page-content {
  min-height: calc(100vh - 164px);
  //min-height: calc(100vh - 184px);
  max-width: 1440px;
  margin: 0 auto;

  > .yxpRow {
    display: block;
  }
}

.page-content:has(.screenset-wrapper-premium){
  min-height: calc(100vh - 164px);
}

.page-content:has(.breadcrumbs-premium){
  min-height: calc(100vh - 164px);

}