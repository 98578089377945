@import '../variables';
@import '../mixins';

.custom-pagination-premium {
  margin: 32px auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;

  .arrow {
    background-image: url("/fe-web/img/icons/arrow-right-simple-white.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 10px auto;
    background-color: rgba(255, 255, 255, 0.10);
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
    height: 40px;
    width: 40px;

    &.prev {
      transform: rotate(180deg);
      margin-right: auto;
    }

    &.next {
      margin-left: auto;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }

  .page {
    @include LibreRegular;
    color: $another-light-gray;
    display: inline-block;
    line-height: 20px;
    font-size: 16px;
    margin: 0 8px;
    text-decoration: none;

    &.active {
      @include LibreBold;
      pointer-events: none;
    }
  }
}