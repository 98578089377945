@import '../../partials/variables';
@import '../../partials/mixins';

.communications {

  &__wrapper {
    display: flex;
    flex-direction: column;
    color: $white;
    align-items: center;
    margin: 14px auto 132px;
    padding: 36px 56px 64px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(241, 241, 241, 0.1);
    backdrop-filter: blur(8px);

    @include max-device('tablet') {
      padding: 36px 20px;
      max-width: 350px;
    }
  }

  &__list{
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  &__card {
    width: 100%;
    max-width: 1160px;
    display: flex;
    border-radius: 8px;
    padding: 24px;
    background-color: $white;
    overflow: hidden;
    position: relative;

    @include max-device('medium-desktop') {
      width: 780px;
    }

  @include max-device('desktop') {
      width: 315px;
    }

    &.unread{
      @include LibreBold;
    }
  }
    &__info {
      display: flex;
      flex-direction: column;
      padding-left:16px;
      width: 100%;

      @include max-device('tablet') {
        flex-direction: column;
      }
    }

    &__subinfo{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }


    &__object {
      //@include lineClamp(2);
      color:$blue;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      //height:40px;
    }

    &__message {
      @include lineClamp(2);
      font-size: 14px;
      color: $gray;
      height:32px;
      line-height: 16px;
      margin-bottom: 16px;

      @include max-device('tablet') {
        @include lineClamp(4);
        height: 64px;
      }
    }

  &__message-detail {
    font-size: 14px;
    color: $gray;
    height:32px;
    line-height: 16px;
    margin-bottom: 16px;

    @include max-device('tablet') {
      height: 64px;
    }
  }

    &__date {
      @include LibreBold;
      color: $blue;
      font-size: 12px;
    }

    &__link {
      @include LibreBold;
      display: inline-block;
      font-size: 14px;
      text-decoration: none;
      color: $blue;
      border-bottom: 1px solid $blue;
      align-self: flex-start;
      transition: opacity linear $default-timing-transition;

      &:hover {
        opacity: 0.8;
      }
    }

&__card.unread:before{
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: $bright-gold;
    border-radius: 50px;
    left: 16px;
  }

}