@import '../../partials/variables';
@import '../../partials/mixins';

.warranty {

  &__wrapper {
    //display: flex;
    //flex-direction: column;
    color: $white;
    align-items: center;
    margin: 14px auto 132px;
    padding: 36px 56px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(241, 241, 241, 0.1);
    backdrop-filter: blur(8px);

    @include max-device('tablet') {
      padding: 36px 20px;
      max-width: 350px;
    }
  }

  &__list{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    h1{
      text-align: center;
    }

    .generic-button{
      background-color: $white;

      a{
        color: $blue;
        text-decoration: none;
      }

      &:after{
        background: url("#{$icon-path}/plus-blue.svg") no-repeat center center;
        width: 16px;
        height: 16px;
      }
    }
  }

  &__cards-wrapper{
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include max-device('desktop'){
      flex-direction: column;
    }
  }

  &__form{
    margin: 14px auto 132px;
    padding: 56px 56px 32px 56px;
    border-radius: 8px;
    border: 1px rgba(255, 255, 255, 0.20);
    background:$lightest-gray;
    backdrop-filter: blur(8px);

    h1{
      text-align: center;
      margin-bottom: 32px;
    }
  }

  #machineResults{
    //padding: 16px 24px;
    background: $white;
    border-radius: 8px;
    position: absolute;
    width: 100%;
    max-height: 48vh;
    overflow: auto;
    z-index: 2;
  }

  .autocomplete-item{
    padding: 7px 24px;
    cursor: pointer;

    &:hover{
      background:$dirty-gray;
    }
  }

  .tab{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .front-tires-form,
  .rear-tires-form{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  .form-cta{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 32px;

    input{
      margin-top: 0;
    }
  }


  .progress-bar-container{
    color: $blue;
    text-align: center;
    width: 100%;
  }

  .progress {
    width: 100%;
    background: $middle-gray;
    height: 3px;
    margin: 10px 0 24px;
    position: relative;

    &.progress-step1:before {
      content: "";
      background: $blue;
      width: 33.3%;
      height: 3px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.progress-step2:before{
      content: "";
      background: $blue;
      width: 66.6%;
      height: 3px;
      position: absolute;
      top:0;
      left: 0;
    }

    &.progress-step3:before{
      content: "";
      background: $blue;
      width: 100%;
      height: 3px;
      position: absolute;
      top:0;
      left: 0;
    }
  }


}