@import '../variables';
@import '../mixins';

.breadcrumbs-premium.breadcrumbs-fullWidth {
  height: auto;
  padding-top: 60px;
  background: transparent;

  //@include max-device('tablet') {
  //  margin: 0 20px;
  //}

  .breadcrumb-title {
    @include LibreBold;
    font-size: 38px;
    color: $white;
    margin-top: 10px;

    @include max-device('tablet') {
      font-size: 32px;
    }
  }

  .breadcrumb {
    @include LibreRegular;
    position: relative;
    padding: 0 25px 0 0;
    font-size: 14px;
    color: $white;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    margin-right: 13px;

    &.active {
      @include LibreBold;

      &:after {
        display: none;
      }
    }

    &:after {
      content: '';
      width: 8px;
      height: 1px;
      background: $white;
      position: absolute;
      right: 0;
      top: auto;
    }
  }
}

