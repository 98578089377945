@import "_variables";
@import "_fonts.scss";

@mixin genericButton {
  @include LibreMedium;
  padding: 12px 50px 12px 16px;
  color: $white;
  background-color: $blue;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  opacity: 1;
  transition: opacity linear $default-timing-transition;

  &:after {
    content: '';
    width: 12px;
    height: 10px;
    position: absolute;
    top: auto;
    right: 18px;
    background: url(/fe-web/img/icons/arrow-right-white.svg) no-repeat center center;
  }

  &:hover {
    opacity: 0.8;
  }
}

@mixin secondaryButton {
  @include LibreMedium;
  padding: 12px 16px!important;
  color: $blue;
  background-color: $white;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  border: 1px solid $blue;

  &:after {
    display: none;
  }
}

@mixin bgImg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin genericDialog {
  @include LibreRegular;
  margin: 2px auto 80px;
  background: #9982421a;
  padding: 32px 24px;
  border-radius: 8px;
  overflow: hidden;
  line-height: 16px;
  font-size: 14px;
  max-width: 100%;
  width: 520px;
  color: $gold;
}

@mixin cardLink {
  @include LibreSemiBold;
  font-size: 14px;
  color: $blue;
  position: relative;
  padding-right: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity linear $default-timing-transition;
  text-decoration: none;

  &:after {
    content: '';
    width: 10px;
    height: 12px;
    position: absolute;
    top: auto;
    right: 5px;
    background: url(/fe-web/img/icons/arrow-right-simple-blue.svg) no-repeat center center;
    background-size: contain;
  }

  &.external:after {
    background: url(/fe-web/img/icons/external.svg) no-repeat center center;
    background-size: contain;
  }

  &:hover {
    opacity: 0.8;
  }
}

@mixin contentWrapperCnt {
  max-width: map-get($devices, 'medium-desktop');
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@mixin lineClamp($lines) {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  white-space: normal;

  * {
    display: inline;
  }
}

@mixin swiper {
  .swiper-pagination {
    height: 10px;
    display: flex;
    align-items: flex-end;
    left: 0;
    z-index: 1;
    position: relative;

    .swiper-pagination-bullet {
      width: 16px;
      height: 8px;
      border-radius: 100px;
      background: $blue;
      opacity: 0.5;
      margin-right: 5px;
      transition: all linear .2s;
      display: block;
      cursor: pointer;

      &-active {
        opacity: 1;
        width: 40px;
      }
    }
  }

  .swiper-navigation {
    display: flex;
    width: 80px;
    justify-content: space-between;
    position: relative;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: url(/fe-web/img/icons/swiper-navigation.svg) no-repeat center center;
    width: 32px;
    height: 32px;
    top: auto;
    margin-top: 0;

    &.swiper-button-disabled {
      opacity: 0.3;
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-next {
    left: 15px;
  }

  .swiper-button-prev {
    transform: rotate(180deg);
  }

  .swiper-nav-pag-cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(.generic-padding) & {
      margin: 0 0 60px;
    }
  }

  .swiper-wrapper {
    @include max-device('tablet') {
      justify-content: flex-start;
    }

    @include min-device('tablet') {
      transform: translate3d(0, 0px, 0px) !important;
    }
  }

  @include min-device('tablet') {
    .swiper-nav-pag-cnt,
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

@mixin swiperPagination {
  .swiper-pagination {
    padding-left: 20px;
    height: 10px;
    display: flex;
    align-items: flex-end;
    left: 0;
    top: 90px;
    position: absolute;
    z-index: 2;

    .swiper-pagination-bullet {
      width: 16px;
      height: 8px;
      border-radius: 100px;
      background: $white;
      opacity: 0.5;
      margin-right: 5px;
      transition: all linear .2s;
      display: block;
      cursor: pointer;

      &-active {
        opacity: 1;
        width: 40px;
      }
    }

    @include min-device('medium-desktop') {
      left: calc((100% - 1280px) / 2);
    }
  }
}


@mixin icon-before($icon, $size:24px) {
  content: '';
  width: $size;
  height: $size;
  position: absolute;
  top: auto;
  left: 16px;
  background: url("#{$icon-path}/#{$icon}.svg") no-repeat center center;
  background-size: contain;
}

@mixin icon-after($icon, $size:24px)  {
  content: '';
  width: $size;
  height: $size;
  position: absolute;
  top: auto;
  right: 16px;
  background: url("#{$icon-path}/#{$icon}.svg") no-repeat center center;
  background-size: contain;
}

@mixin loading($position: fixed) {
  &::after {
    content: '';
    border: 4px solid transparent;
    border-top-color: $blue;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: $position;
    z-index: 10;
    top: calc(50% - 20px);
    left: calc(50% - 20px);

  }

  &::before {
    content: ' ';
    position: $position;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background: rgba(white, 0.5);
    top: 0;
    left: 0;
  }
}
