@import '../partials/variables';
@import '../partials/mixins';

.bannerFullWidth-wrapper {
  @include swiperPagination;
  position: relative;

  .swiper-wrapper {
    width: 100%;
    display: flex;
  }
}

.bannerFullWidth-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include max-device('tablet') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.bannerFullWidth-cnt {
  @include bgImg;
  padding: 150px 0 50px;
  flex: 1 0 100%;
}

.bannerFullWidth-button {
  @include max-device('tablet') {
    margin-top: 30px;
  }
}

.bannerFullWidth-title {
  @include LibreMedium;
  font-size: 14px;
  color: $white;
}

.bannerFullWidth-subtitle {
  @include LibreBlack;
  @include lineClamp(2);
  margin-top: 15px;
  font-size: 28px;
  line-height: 32px;
  height: 64px;
  color: $white;
  max-width: 390px;

  @include max-device('tablet') {
    @include LibreMedium;
    line-height: 24px;
    font-size: 21px;
    height: 48px;
  }
}