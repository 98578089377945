@import '../../partials/variables';
@import '../../partials/mixins';


.offerDetailPremium{

  &__news {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 56px;
    border-radius: 8px;
    margin: 14px 0;
  }

  &__img {
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 70px;
    width: 100%;

    @include max-device('tablet') {
      margin-bottom: 40px;
    }
  }

  &__title {
    @include LibreSemiBold;
    font-size: 28px;
    color: $dark-gray;
    line-height: 1.2;
    margin-bottom: 30px;

    @include max-device('tablet') {
      font-size: 24px;
    }
  }

  &__date {
    @include LibreMedium;
    font-size: 14px;
    color: $blue;
    margin-bottom: 30px;
  }

  &__descr {
    color: $dark-gray;
    line-height: 24px;
    font-size: 16px;

    @include max-device('tablet') {
      font-size: 14px;
    }
  }
}
