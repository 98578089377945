@import '../partials/variables';
@import '../partials/mixins';

.custom-form {
  padding-bottom: 80px;

  form .yxpRow {
    @include LibreRegular;
    font-size: 18px;
  }

  .yxpHtmlTextWrapper {
    @include LibreRegular;
    padding-bottom: 30px;
    line-height: 26px;
    margin-top: 100px;
    font-size: 18px;
  }

  .yxpType_DOCUMENT {
    @include LibreSemiBold;
    margin-bottom: 50px;
    cursor: pointer;
  }

  .yxpType_UPLOAD_INPUT {
    margin: 0 0 15px;
    max-width: 50%;
    padding: 0;

    .yxp-error {
      border: 1px solid red;
    }

    .yxp-error-msg {
      display: none;
    }

    .yxpInputGroup {
      position: relative;

      .erase {
        display: none;
      }

      &.has-value {
        &:after {
          background: url(/fe-web/img/icons/checked.svg) no-repeat center center;
          position: absolute;
          margin-left: 0;
          margin-top: -15px;
          display: block;
          height: 40px;
          width: 40px;
          content: "";
          left: 100%;
          top: 50%;
        }

        .erase {
          background: url(/fe-web/img/icons/trash.svg) no-repeat center center;
          background-size: 20px;
          margin-left: -25px;
          position: absolute;
          margin-top: -8px;
          display: block;
          cursor: pointer;
          width: 30px;
          height: 30px;
          left: 100%;
          top: 50%;
        }
      }
    }
  }

  .yxpSubmitBtn {
    margin-top: 30px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    background: $blue;
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    color: $white;
    align-self: flex-start;
    cursor: pointer;
    transition: opacity linear $default-timing-transition;
  }

  .formResponseOK {
    @include LibreSemiBold;
    color: darkgreen;
  }
}