@import '../partials/variables';
@import '../partials/mixins';

.newsDetail-news {
  display: flex;
  flex-direction: column;
  margin-top: -110px;
  max-width: 1056px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 200px;
}

.newsDetail-img {
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 70px;
  width: 100%;

  @include max-device('tablet') {
    margin-bottom: 40px;
  }
}

.newsDetail-title {
  @include LibreSemiBold;
  font-size: 28px;
  color: $dark-gray;
  line-height: 1.2;
  margin-bottom: 30px;

  @include max-device('tablet') {
    font-size: 24px;
  }
}

.newsDetail-date {
  @include LibreMedium;
  font-size: 14px;
  color: $blue;
  margin-bottom: 30px;
}

.newsDetail-descr {
  color: $dark-gray;
  line-height: 24px;
  font-size: 16px;

  @include max-device('tablet') {
    font-size: 14px;
  }
}