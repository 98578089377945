@import "../../partials/_variables";

.profile{
  &__form-wrapper{
    display: flex;
    flex-direction: column;
    color: $dark-gray;
    align-items: center;
    margin: 14px auto 132px;
    padding: 56px 56px 32px 56px;
    border-radius: 8px;
    border: 1px rgba(255, 255, 255, 0.20);
    background:$lightest-gray;
    backdrop-filter: blur(8px);

    @include max-device('tablet'){
      padding: 56px 20px;
    }
  }

  /* Style the tab */
  .tab {
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: space-around;
    margin: 32px 0 24px;
  }

  .tablinks{
    width:100% ;
    text-align: center;
    border-bottom: 3px solid $middle-gray;
    cursor: pointer;

    @include max-device('tablet'){
      min-width: 100px;
    }
  }

  .tablinks.active{
    border-bottom: 3px solid $blue;
    color: $blue;
  }

  .tabs__tab {
    display: none;
    flex-direction: column;
    gap: 24px;
  }

  .tabs__tab.active{
    display: flex;

  }

  .tab-content {
    width: 100%;
  }

  .tabs {
    display: flex;
    flex-direction: column;
  }

  .tab-account{
    align-items: center;

    .account-preview{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    h4{
      margin-left: 0;
    }

    .generic-button:after{
      background: url("#{$icon-path}/change-psw.svg") no-repeat center center;
      width: 16px;
      height: 16px;
    }

    .account-password{
      width: 100%;
      max-width: 512px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    .form-col{
      width: 100%;
    }

    .form-cta{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      input{
        margin-top: 0;
      }
    }
  }
}