@import '../partials/variables';
@import '../partials/mixins';

.newsListing-wrapper {
  min-height: calc(100vh - 184px);
}

.newsListing-cardsWrapper {
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .newsListing-cardWrapper:first-of-type {
    box-shadow: 0 12px 18px rgba(22, 22, 22, 0.15);
  }

  @include max-device('tablet') {
    margin: 50px auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.newsListing-cardWrapper {
  flex: 0 1 49%;
  margin: 24px 0 0;
  display: flex;
  border-radius: 16px;
  border-bottom: 1px solid $lightest-gray;
  background-color: $white;
  overflow: hidden;

  @include max-device('tablet') {
    flex: 0 0 100%;
    flex-direction: column;
  }
}

.newsListingCard-info {
  display: flex;
  flex-direction: column;
  padding: 25px 32px;
}

.newsListingCard-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 192px;
  height: 100%;
  flex: 0 0 192px;
  border-radius: 16px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  @include max-device('tablet') {
    width: 100%;
    height: 144px;
    background-size: cover;
    border-bottom-left-radius: 0;
    margin-right: 0;
  }
}

.newsListingCard-date {
  @include LibreMedium;
  color: $blue;
  font-size: 14px;
  margin-bottom: 10px;
}

.newsListingCard-title {
  @include LibreBold;
  @include lineClamp(2);
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 16px;

  @include max-device('tablet') {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
  }
}

.newsListingCard-subtitle {
  @include lineClamp(3);
  font-size: 14px;
  color: $gray;
  line-height: 18px;
  margin-bottom: 24px;

  a {
    display: none;
  }
}

.newsListingCard-link {
  @include LibreMedium;
  display: inline-block;
  font-size: 14px;
  margin-top: auto;
  text-decoration: none;
  color: $blue;
  padding-bottom: 4px;
  border-bottom: 1px solid $blue;
  align-self: flex-start;
  transition: opacity linear $default-timing-transition;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
}

.custom-pagination {
  margin: 96px auto 218px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;

  .arrow {
    background-image: url("/fe-web/img/icons/arrow-right-simple-white.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 10px auto;
    background-color: $blue;
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 34px;
    height: 40px;
    width: 40px;

    &.prev {
      transform: rotate(180deg);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }

  .page {
    @include LibreRegular;
    color: $another-light-gray;
    display: inline-block;
    line-height: 20px;
    font-size: 16px;
    margin: 0 8px;

    &.active {
      pointer-events: none;
      color: $blue;
    }
  }
}