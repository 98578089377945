@import '../../partials/variables';
@import '../../partials/mixins';
@import '../../partials/visual-slide';

.visual-premium{

  &.yxpBlock{
    padding: 0!important;
  }
  margin: 45px 0;

  .visual-slide{
    padding: 0;
    border-radius: 8px;

    &__info{
      padding: 130px 118px;

      @include max-device('tablet') {
        padding: 36px 30px;
      }
    }

    &__descr{
      display: flex;
      flex-direction: column;
    }

    &__subtitle {
      @include LibreRegular;
      font-size: 14px;
      margin-bottom: 5px;

    }

    &__title {
      @include LibreExtraBold;
      font-size: 28px;
      margin-bottom: 16px;

      @include max-device('tablet') {
        @include LibreSemiBold;
        font-size: 21px;
      }
    }

    &__wrapper{
      display: block;
    }

    &__intro{
      margin: 16px 0 32px;
      padding: 0;
      background-color: transparent;
      color: $white;
      line-height: normal;
      font-size: 14px;
    }

    &__button{
      @include secondaryButton;
    }
  }


}