@import '../partials/variables';
@import '../partials/mixins';
@import '../partials/visual-slide';

.visual {
  @include swiperPagination;
  min-height: 296px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;

  @include max-device('tablet') {
    min-height: 410px;
  }

  .swiper-wrapper {
    height: auto;
  }

  .swiper-pagination {
    top: 25px;
  }

  &__wrapper {
    display: flex;
  }
}
