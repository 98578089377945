@import '../partials/variables';
@import '../partials/mixins';

.documentsRepository-wrapper {
  padding-bottom: 100px;
}

.documentsRepository-breadcrumbs {
  margin-bottom: 80px;
}

.documentRepository-item {
  @include LibreBold;
  font-size: 21px;
  padding: 40px;
  background: $light-blue;
  color: $blue;
  margin-bottom: 25px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity linear $default-timing-transition;
  cursor: pointer;
  text-decoration: none;

  &:after {
    content: '';
    width: 10px;
    height: 12px;
    background: url(/fe-web/img/icons/arrow-right-simple-blue.svg) no-repeat center center;
    background-size: contain;
    margin-left: 20px;
  }

  &:hover {
    opacity: 0.8;
  }

  @include max-device('tablet') {
    padding: 20px;
    font-size: 18px;
  }
}
