@import '../partials/variables';
@import '../partials/mixins';

.yxpImageTextWrapper {
  .yxpImageTextTitle {
    @include LibreBold;
    font-size: 28px;

    &:not(:empty) {
      margin-bottom: 16px;

      @include max-device('desktop') {
        margin-bottom: 32px;
      }
    }
  }

  .yxpImageWrapper {
    display: flex;

    @include min-device('desktop') {
      .mobile {
        display: none;
      }
    }

    @include max-device('desktop') {
      .yxpImage {
        display: none;

        &:last-of-type {
          display: block;
        }
      }
    }
  }

  .yxpHtmlTextWrapper {
    color: $gray;
  }

  .yxpLink {
    @include genericButton;
    margin-top: 56px;

    @include max-device('desktop') {
      margin-top: 32px;
    }
  }

  &.yxpFlexWrapper {
    display: flex;
    justify-content: space-between;

    .yxpImageWrapper {
      flex: 0 50%;
      max-width: 569px;

      + .yxpInfoWrapper {
        padding-right: 0;
        padding-left: 4%;
      }

      @include max-device('desktop') {
        max-width: none;
      }
    }

    .yxpInfoWrapper {
      flex: 0 50%;
      padding-right: 4%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .yxpImageWrapper {
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
