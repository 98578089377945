.visual-slide {
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    opacity: 0.6;
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
  }

  &__title {
    @include LibreRegular;
    font-size: 14px;
    margin-bottom: 5px;
  }

  &__subtitle {
    @include LibreExtraBold;
    font-size: 28px;

    @include max-device('tablet') {
      @include LibreSemiBold;
      font-size: 21px;
    }
  }

  &__title,
  &__subtitle {
    color: $white;
    line-height: 1.2;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include max-device('tablet') {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title-wrapper {
    max-width: 50%;
  }

  &__descr {
    position: relative;
    z-index: 1;

    &.linkAlone {
      display: flex;
      justify-content: space-between;

      @include max-device('tablet') {
        flex-direction: column;

        .visual-slide__button {
          margin-top: 37px;
        }
      }
    }
  }

  &__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__intro {
    margin-top: 37px;
    border-radius: 8px;
    padding: 16px;
    background-color: $blue;
    color: $white;
    max-width: 390px;

    &[data-style="blue"] {
      background-color: $blue;
      color: $white;
    }

    &[data-style="white"] {
      background-color: $white;
      color: $blue;
    }

    @include max-device('tablet') {
      margin-bottom: 24px;
      max-width: 75%;
    }
  }
}
