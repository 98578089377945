@import "../../partials/_variables";

.register-fill{

  &__form-wrapper{
    display: flex;
    flex-direction: column;
    color: $dark-gray;
    align-items: center;
    margin: 14px auto 132px;
    padding: 56px 56px 32px 56px;
    border-radius: 8px;
    border: 1px rgba(255, 255, 255, 0.20);
    background:$lightest-gray;
    backdrop-filter: blur(8px);

    @include max-device('tablet'){
      padding: 56px 20px;
    }
  }

  h1{
    text-align: center;
  }

  .premium-form{
    margin-top: 32px;
  }

}