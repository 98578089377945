@import '../partials/variables';
@import '../partials/mixins';

.newsList-wrapper {
  height: 744px;
  margin-right: 2.3%;
  box-shadow: 0 12px 18px rgb(22 22 22 / 15%);

  @include max-device('tablet') {
    height: auto;
    box-shadow: none;
  }
}

.newsList-img {
  @include bgImg;
  background-image: url('/fe-web/img/test/newsAsideWrapper.jpg');
  height: 145px;
}

.newsList-cardsWrapper {
  margin: 15px 20px 15px 15px;
  display: flex;
  flex-direction: column;

  @include max-device('tablet') {
    min-height: 120px;
  }
}

.newsCard-wrapper {
  padding: 15px 0;
  border-top: 1px solid $lightest-gray;
  display: flex;
}

.newsCard-img {
  @include bgImg;
  width: 104px;
  height: 104px;
  flex: 1 0 104px;
  margin-right: 20px;
  flex-grow: 0;
}

.newsCard-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.newsCard-title {
  @include LibreSemiBold;
  @include lineClamp(2);
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 10px;
}

.newsCard-subtitle {
  @include LibreRegular;
  @include lineClamp(2);
  font-size: 12px;
  line-height: 13px;
  color: $middle-gray;

  a {
    display: none;
  }
}

.newsCard-dateLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.newsCard-date {
  @include LibreMedium;
  font-size: 12px;
  color: $blue;
}

.newsCard-link {
  @include LibreSemiBold;
  font-size: 14px;
  text-decoration: none;
  padding-bottom: 5px;
  border-bottom: 1px solid $blue;
  color: $blue;
}
