@import '../../partials/variables';
@import '../../partials/mixins';

.myFleet {

  &__wrapper {
    display: flex;
    flex-direction: column;
    color: $white;
    align-items: center;
    margin: 14px auto 132px;
    padding: 36px 56px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(241, 241, 241, 0.1);
    backdrop-filter: blur(8px);

    @include max-device('tablet') {
      padding: 36px 20px;
      max-width: 350px;
    }
  }

  &__main-screen{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .generic-button{
      background-color: $white;

      a{
        color: $blue;
        text-decoration: none;
      }

      &:after{
        background: url("#{$icon-path}/plus-blue.svg") no-repeat center center;
        width: 16px;
        height: 16px;
      }
    }


  }

  &__cards-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    @include max-device('desktop'){
      flex-direction: column;
    }
  }

  &__form{
    margin: 14px auto 132px;
    padding: 56px 56px 32px 56px;
    border-radius: 8px;
    border: 1px rgba(255, 255, 255, 0.20);
    background:$lightest-gray;
    backdrop-filter: blur(8px);

    @include max-device('tablet'){
      padding: 56px 20px 32px;
    }

    h1{
      text-align: center;
      margin-bottom: 32px;
    }
  }

  #machineBrandResults{
    //padding: 16px 24px;
    background: $white;
    border-radius: 8px;
    position: absolute;
    width: 100%;
    max-height: 48vh;
    overflow: auto;
    z-index: 2;
  }

  .autocomplete-item{
    padding: 7px 24px;
    cursor: pointer;

    &:hover{
    background:$dirty-gray;
  }
  }

}