@import '../partials/variables';
@import '../partials/mixins';

.homeCard-wrapper {
  height: 360px;

  &:nth-of-type(3),
  &:nth-of-type(5) {
    margin-left: 2.3%;

    @include max-device('tablet') {
      margin-left: 0;
    }
  }

  &:nth-of-type(7) {
    margin-left: 2.3%;
    margin-right: 2.3%;

    @include max-device('tablet') {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include max-device('tablet') {
    height: auto;
    min-height: 350px;
  }
}

.homeCard-img {
  @include bgImg;
  height: 145px;
}

.homeCard-title-wrapper {
  padding-bottom: 15px;
  border-bottom: 1px solid $lightest-gray;
}

.homeCard-subtitle {
  @include LibreRegular;
  @include lineClamp(6);
  margin-top: 15px;
  color: $middle-gray;
  font-size: 14px;

  &.sales {
    height: 46px;
  }
}

.target-wrapper {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  display: flex;

  .ytd {
    text-align: left;
    color: $blue;
    width: 50%;
  }

  .tgt {
    color: $dark-gray;
    text-align: right;
    width: 50%;
  }

  .label {
    @include LibreRegular;
    margin-bottom: 4px;
    line-height: 16px;
    font-size: 14px;
    color: $black;
  }

  .value {
    @include LibreBlack;
    line-height: 32px;
    font-size: 24px;
  }

  .bar {
    background: $lightest-gray;
    margin: 8px auto 0;
    height: 16px;
    width: 100%;

    .progress {
      background: $blue;
      display: block;
      height: 100%;
      float: left;
    }
  }
}