@import '../partials/variables';
@import '../partials/mixins';

.registration-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;


  &:after {
    display: block;
    clear: both;
    float: none;
    content: "";
  }

  &.contact {
    .registration-formWrapper {
      margin: 0 auto;
      padding: 20px;
      float: none;
      width: 100%;
    }

    .registration-form {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      .inputGroup {
        width: 48%;

        &[fullwidth] {
          width: 100%;
        }

        @include max-device('tablet') {
          width: 100%;
        }
      }
    }
  }

  .registration-img {
    //background-image: url("/fe-web/img/bg/screenset.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 818px;
    float: left;
    width: 40%;

    @include max-device('tablet') {
      min-height: 34vh;
      width: 100%;
    }
  }

  .registration-formWrapper {
    padding: 20px 100px 100px;
    float: left;
    width: 60%;


    @include max-device('tablet') {
      padding: 20px;
      width: 100%;
    }
  }

  .registration-intro {
    flex-direction: column;
    align-items: center;
    margin: 0 auto 50px;
    color: $dark-gray;
    max-width: 100%;
    display: flex;
    width: 520px;
  }

  .registration-title {
    @include LibreBlack;
    color: $dark-gray;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .registration-subtitle {
    @include LibreMedium;
    color: $dark-gray;
    font-size: 14px;
    line-height: 1.1;
  }

  .registration-already {
    text-decoration: underline;
    font-size: 12px;
    margin-bottom: 40px;

    a {
      @include LibreSemiBold;
      color: $dark-gray;
    }
  }

  .registration-dialog {
    @include genericDialog;
  }

  .registration-form {
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    width: 520px;

    &.touched {
      input,
      textarea,
      select {
        &:invalid {
          border: 1px solid red;
        }
      }
    }

    .inputGroup {
      display: flex;
      flex-direction: column;
      margin-bottom: 35px;

      label {
        @include LibreSemiBold;
        margin-bottom: 10px;
        color: $dark-gray;
        display: flex;
        align-items: center;
      }

      input,
      textarea,
      select {
        outline: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background: $less-white;
        border-radius: 8px;
        padding: 15px 25px;
        font-size: 14px;
        color: $dark-gray;

        &::-webkit-input-placeholder,
        &::placeholder {
          color: $dirty-gray;
          font-size: 14px;
        }

        &[readonly] {
          color: #39393970;
        }
      }

      select {
        background-image: url(/fe-web/img/icons/arrow-down-gray.svg);
        background-repeat: no-repeat;
        background-position: 95% center;
      }

      textarea {
        @include LibreRegular;
        resize: none;
        height: 250px;
      }

      &[data-required="required"] {
        label:after {
          content: '*';
        }
      }
    }

    .inputCheck {
      margin-bottom: 20px;
      color: $dark_gray;
      font-size: 14px;
      display: flex;
      align-items: center;

      a {
        @include LibreSemiBold;
        text-decoration: underline;
        color: $dark-gray;
      }
    }

    .required-disclaimer {
      font-size: 14px;
      margin-bottom: 40px;
      color: $dirty-gray;
    }

    [type="submit"] {
      outline: none;
      appearance: none;
      -webkit-appearance: none;
      background: $blue;
      border: none;
      border-radius: 8px;
      padding: 12px 16px;
      color: $white;
      align-self: flex-start;
      cursor: pointer;
      transition: opacity linear $default-timing-transition;

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
}