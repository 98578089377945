@import '../../partials/variables';
@import '../../partials/mixins';


.yxpMainContainer.premium-layout{
  background:  url("#{$image-path}/bg/BKG.jpg") no-repeat center center;
  background-size: cover;
}


.splashPagePremium {
  padding: 50px 20px 80px;
  color: $white;

  .content-wrapper-cnt {
    display: flex;
    flex-direction: column;
  }

  &__title,
  &__subtitle {
    line-height: 32px;
    text-align: center;
  }

  &__title {
    @include LibreExtraBold;
    font-size: 28px;
    margin-bottom: 5px;
  }

  &__subtitle {
    @include LibreLight;
    font-size: 16px;
    margin-bottom: 40px;
    border-radius: 0 0 8px 8px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(8px);
    padding: 24px 8px;
    margin-top: -2px;

    @include max-device('tablet') {
      margin-top: -1px;
    }
  }

  &__text-accordion{
    @include LibreBold;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  &__country-content {
    font-size: 14px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap: 32px;

    @include max-device('tablet') {
      gap: 10px;
      padding: 7px;
    }

  }

  &__country-list {
    border-radius: 8px;
    padding: 8px;
    background: $lightest-gray;
    width: 180px;

    @include max-device('tablet') {
      width: 155px;
    }

    &:hover {
      @include LibreSemiBold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__country-link {
    color: $blue;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      @include LibreBold;
      text-decoration: underline;
    }
  }

  &__country-flag{
    width: 32px;
    height: 32px;
    border-radius: 50px;
    border: 3px solid $white;
    box-shadow: -1px 0px 11px 0px rgba(0, 0, 0, 0.55);
  }

  &__image {
    padding-top: 32px;

    @include max-device('medium-desktop') {
      padding-top: 10px;
    }
  }

  @include max-device('tablet') {
    padding: 30px 0;
  }
}
