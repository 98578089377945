@import "../../partials/_variables";

.screenset-wrapper-premium {
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    display: block;
    clear: both;
    float: none;
    content: "";
  }


  #screensets-cnt {
    float: left;
    width: 60%;
    color: $white;
    text-align: center;

    @include max-device('tablet') {
      width: 100%;
    }
  }

  .register {
    @include LibreRegular;
    text-align: center;
    line-height: 12px;
    margin: 20px auto 0;
    font-size: 12px;
    float: right;
    width: 60%;
    color: $white;

    a {
      @include LibreBold;
      color: $white;
      cursor: pointer;
    }

    @include max-device('tablet') {
      margin: 0 auto 20px;
      float: none;
    }
  }

  .alert-info {
    @include genericDialog;
    position: relative;
    text-align: center;
    margin-top: 40px;
    max-width: 45%;
    top: 45px;

    @include max-device('tablet') {
      max-width: 75%;
    }
  }
}