@import "partials/_variables";

.home-cnt {
  background-color: $light-gray;
  overflow: hidden;

  .hero-cnt {
    background-color: $gold;
    height: 300px;
    width: 100%;
    color: $white;
  }
}

.news-wrapper {
  margin: 20px auto 150px;

  @include max-device('tablet') {
    display: flex;
    flex-direction: column;
  }
}

.newsList-wrapper,
.homeCard-wrapper {
  float: left;
  width: 31.8%;
  background: $white;
  border-radius: 16px;
  margin-bottom: 24px;
  overflow: hidden;

  @include max-device('tablet') {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.newsList-info,
.homeCard-info {
  margin: 15px;
}

