@font-face {
  font-family: 'Libre-Light';
  src: url('/fe-web/assets/fonts/LibreFranklin-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Libre-Regular';
  src: url('/fe-web/assets/fonts/LibreFranklin-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Libre-Medium';
  src: url('/fe-web/assets/fonts/LibreFranklin-Medium.ttf') format('truetype');

}

@font-face {
  font-family: 'Libre-SemiBold';
  src: url('/fe-web/assets/fonts/LibreFranklin-SemiBold.ttf') format('truetype');

}

@font-face {
  font-family: 'Libre-Bold';
  src: url('/fe-web/assets/fonts/LibreFranklin-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Libre-ExtraBold';
  src: url('/fe-web/assets/fonts/LibreFranklin-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Libre-Black';
  src: url('/fe-web/assets/fonts/LibreFranklin-Black.ttf') format('truetype');
}

@mixin fontGeneric {
  font-weight: normal;
  font-style: normal;
}

@mixin LibreLight {
  @include fontGeneric;
  font-family: 'Libre-Light', sans-serif;
}

@mixin LibreRegular {
  @include fontGeneric;
  font-family: 'Libre-Regular', sans-serif;
}

@mixin LibreMedium {
  @include fontGeneric;
  font-family: 'Libre-Medium', sans-serif;
}

@mixin LibreSemiBold {
  @include fontGeneric;
  font-family: 'Libre-SemiBold', sans-serif;
}

@mixin LibreBold {
  @include fontGeneric;
  font-family: 'Libre-Bold', sans-serif;
}

@mixin LibreExtraBold {
  @include fontGeneric;
  font-family: 'Libre-ExtraBold', sans-serif;
}

@mixin LibreBlack {
  @include fontGeneric;
  font-family: 'Libre-Black', sans-serif;
}
