@import '../../partials/variables';
@import '../../partials/mixins';

.warranty-card{
  min-width: 366px;
  min-height: 421px;
  padding: 24px;
  background: $lightest-gray;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);

  &:before{
    @include icon-before('tractor');
    left: unset;
  }

  @include max-device('desktop-plus'){
    max-width: 295px;
    min-width: 295px;
  }

  &__name{
    @include LibreBold();
    font-size: 18px;
    line-height: 24px;
    color: $blue;
    margin: 0 0 32px 32px;
  }

  &__info{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }


  &__row{
    display: flex;
    justify-content: space-between;

    @include max-device('desktop'){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__detail{
    @include LibreBold();
    font-size: 14px;
    line-height: 20px;
    color: $dark-gray;
  }

  &__spec{
    @include LibreRegular();
    font-size: 13px;
    line-height: 20px;
    color: $blue;
    border-radius: 4px;
    background: rgba(17, 46, 106, 0.10);
    padding: 2px 8px;

    &.status{
      @include LibreBold();
      color: $white;
      text-transform: uppercase;
      &__sent{
        border: 1px solid #6E550E;
        background: $gold;
      }

      &__accepted{
        border: 1px solid #076410;
        background: #0B9619;
      }

      &__refused{
        border: 1px solid #6D0404;;
        background: #F00;
        position: relative;

        img{
          width: 16px;
          height: 16px;
          vertical-align: text-top;
          margin-left: 4px;
        }

        .tooltiptext {
          @include LibreRegular();
          font-size: 10px;
          line-height: 12px;
          visibility: hidden;
          width: 203px;
          background: #E9ECEF;
          color: $dark-gray;
          border-radius: 6px;
          padding: 8px 12px;
          position: absolute;
          z-index: 1;
          bottom: 172%;
          left: 0;

          &:after{
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 15px;
            border-style: solid;
            border-color: #E9ECEF transparent transparent transparent;
          }
        }

        &:hover .tooltiptext {
          visibility: visible;
        }
      }
    }
  }

  &__actions{
    @include LibreSemiBold();
    font-size: 16px;
    line-height: normal;
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 35px;

    img{
      width: 24px;
      height: 24px;
      vertical-align: bottom;
    }
  }

  &__download a{
    color: $gold;
    text-decoration: none;
  }
}