@import '../../partials/variables';
@import '../../partials/mixins';


.file-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 365px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: linear-gradient(0deg, rgba(216, 216, 216, 0.30) 0%, rgba(216, 216, 216, 0.30) 100%), #FFF;
  background-blend-mode: multiply, normal;

  @include max-device('tablet') {
   width: 315px;
  }

  &__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__icon{
    width: 40px;
    height: 40px;
  }

  &__title{
    @include LibreBold;
    font-size: 20px;
    color: $blue;
    margin-bottom: 16px;
  }

  &__descr{
    @include LibreRegular;
    font-size: 14px;
    color: $dark-gray;
  }

}