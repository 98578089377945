$black: #000000;
$light-gray: #E9E9E9;
$lightest-gray: #F1F1F1;
$another-light-gray: #DCDCDC;
$almost-white: #F3F5F8;
$less-white: #F8F8F8;
$middle-gray: #AAAAAA;
$gray: #666666;
$border-gray: #DDDDDD;
$dirty-gray: #99A0AF;
$dark-gray: #393939;
$white: #ffffff;
$blue: #112E6A;
$premium-blue: #05173E;
$azure: #1893B9;
$light-blue: #E7EAF0;
$gold: #998242;
$bright-gold:#D4A92F;
$disabled: #9c9c9c;
$devices: (
        phone: 320px,
        middle-phone: 450px,
        tablet: 768px,
        desktop: 1024px,
        desktop-plus: 1025px,
        medium-desktop: 1280px,
        large-desktop: 1600px
);

@mixin min-device($device: map-get($devices, 'phone')) {
  @if map-has-key($devices, $device) {
    @media screen and (min-width: map-get($devices, $device)) {
      @content;
    }
  }
}

@mixin max-device($device: map-get($devices, 'phone')) {
  @if map-has-key($devices, $device) {
    @media screen and (max-width: (map-get($devices, $device) - 1)) {
      @content;
    }
  }
}

@mixin only-device($device: map-get($devices, 'phone')) {
  @if map-has-key($devices, $device) {
    $devices-length: length($devices);
    $map-list: map-keys($devices);

    @if index($map-list, $device) == $devices-length {
      @include min-device($device) {
        @content;
      }
    } @else {
      $next-device-index: index($map-list, $device) + 1;
      $next-device-key: nth($map-list, $next-device-index);

      @media screen and (min-width: map-get($devices, $device)) and (max-width: (map-get($devices, $next-device-key) - 1)) {
        @content;
      }
    }
  }
}

@mixin between-devices(
  $min-device: map-get($devices, 'phone'),
  $max-device: map-get($devices, 'tablet')
) {
  @if map-has-key($devices, $min-device) and
        map-has-key($devices, $max-device) {
    @media screen and (min-width: map-get($devices, $min-device)) and (max-width: (map-get($devices, $max-device) - 1)) {
      @content;
    }
  }
}

//Timing
$default-timing-transition: .3s;
$fastest-timing-transition: .1s;


$image-path: '/fe-web/img/';
$icon-path: '/fe-web/img/icons';