@import '../../partials/variables';
@import '../../partials/mixins';


.cardIcon {
  width: 360px;
  max-height: 336px;
  background: white;
  border-radius: 8px;
  color: $blue;
  padding: 20px;

  @include max-device('tablet') {
    width: 315px;
  }

  a{
    text-decoration: none;
    color: $blue;
  }

  &__img{
    background-size: cover;
    //width: 336px;
    height: 184px;
    border-radius: 8px;
  }

  &__icon {
    width: 40px;
    float: right;
    margin: 24px 24px auto auto;
  }

  &__info{
    margin-top: 16px;
  }

  &__category{
    @include LibreBold;
    font-size: 20px;;
    line-height: 20px;
    margin-bottom: 16px;
  }

  &__description {
    @include LibreRegular;
    font-size: 16px;
    line-height: 20px;
    height: 60px;
    color: $dark-gray;
    //line-clamp: 3;
    -webkit-line-clamp: 3;
    //--max-lines: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }


}