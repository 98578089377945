@import '../../partials/variables';
@import '../../partials/mixins';

.bannerFullWidth-wrapper {
  @include swiperPagination;
  position: relative;

  .swiper-wrapper {
    width: 100%;
    display: flex;
  }
}


.bannerFullWidthPremium-cnt {
  @include bgImg;
  padding: 150px 50px 150px;
  flex: 1 0 100%;
  @include max-device('tablet') {
    padding: 90px 0;
  }
}

.bannerFullWidthPremium-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include max-device('tablet') {
    flex-direction: column;
    align-items: flex-start;
  }

}

//.bannerFullWidthPremium-descr{
//  padding: 150px 50px 150px;
//}

.bannerFullWidthPremium-button {
  margin-top: 32px;
  background-color: $white;
  cursor: pointer;
  border-radius: 8px;
  border:none;
  position: relative;
  display: inline-flex;
  align-items: center;

  &:after{
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: auto;
    right: 18px;
    background: url("#{$icon-path}/arrow-link.svg") no-repeat center center;
  }

  a{
    font-size: 14px;
    text-decoration: none;
    color: $blue;
    padding: 12px 50px 12px 16px !important;
  }
}

.bannerFullWidthPremium-title {
  @include LibreBlack;
  font-size: 32px;
  line-height: 32px;
  color: $white;
}

.bannerFullWidthPremium-subtitle {
  @include LibreMedium;
  @include lineClamp(2);
  margin-top: 15px;
  font-size: 18px;
  line-height: normal;
  color: $white;
  max-width: 390px;


}

