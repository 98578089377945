@import 'variables';
@import 'mixins';

.generic-padding.generic-padding  {
  padding: 55px 0;

  > .yxpRow {
    @include contentWrapperCnt;
    justify-content: space-between;

    @include max-device('desktop') {
      flex-direction: column;
    }
  }

  > .yxpImageTextWrapper {
    @include contentWrapperCnt;
  }

  .yxpBlock {
    padding: 0;
  }
}

.landing-wrapper.landing-wrapper {
  overflow: hidden;

  .yxpFlexWrapper {
    @include max-device('desktop') {
      flex-direction: column;

      .yxpImageWrapper + .yxpInfoWrapper,
      .yxpInfoWrapper + .yxpImageWrapper {
        margin-top: 42px;
        padding-left: 0;
      }
    }
  }

  .yxp-cols-6 {
    @include max-device('desktop') {
      width: 100%;
    }
  }
}

.block-distance {
  > .yxpRow {
    > .yxpBlock {
      margin-right: 10px;
      margin-left: 10px;

      @include max-device('desktop') {
        width: calc(100% - 40px);
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 32px;
      }
    }

    @include max-device('desktop') {
      &:not(.swiper-wrapper) {
        flex-direction: column;
      }

      &.swiper-wrapper {
        margin-left: 0;
      }
    }
  }
}

.products.products {
  .yxpBlock {
    border: 1px solid $border-gray;
    border-radius: 8px;
  }

  .yxpImageTextTitle,
  .yxpHtmlTextWrapper {
    padding-right: 20px;
  }

  .yxpImageTextTitle {
    font-size: 21px;
  }

  .yxpImageTextWrapper.yxpFlexWrapper {
    justify-content: flex-start;

    .yxpImageWrapper {
      flex: 0 0 auto;
      border-radius: 0;
    }

    .yxpInfoWrapper {
      flex: 1;

      @include max-device('desktop') {
        padding: 16px;
      }
    }
  }

  &[component="GenericSliderComponent"] {
    &.swiper {
      @include swiper;

      @include max-device('desktop') {
        .swiper-slide {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .swiper-controls {
        display: flex;
        justify-content: space-between;
        position: relative;
      }

      .swiper-button-prev,
      .swiper-button-next {
        position: relative;
        left: 0;
      }
    }

    > .yxpRow {
      align-items: flex-start;
    }

    .yxpImageTextTitle,
    .yxpHtmlTextWrapper {
      padding: 11px 22px;
    }

    .yxpImageTextTitle {
      font-size: 13px;
      margin-bottom: 0;
    }

    .yxpHtmlTextWrapper {
      font-size: 10px;
    }

    .yxpLink {
      margin: 11px 22px;
      font-size: 9px;
    }
  }
}
