@import '../partials/variables';
@import '../partials/mixins';

.documentsRepositoryDetail-breadcrumbs {
  margin-bottom: 80px;
}

.documentsRepositoryDetail-list {
  padding: 60px;
  background: $almost-white;
  border-radius: 8px;
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include max-device('tablet') {
    flex-direction: column;
    padding: 20px;
  }

  .custom-pagination {
    margin-bottom: 0;
  }
}

.documentsRepositoryDetail-item {
  flex: 0 1 49%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @include max-device('tablet') {
    flex: 1 0 100%;
  }
}

.documentsRepositoryDetail-file {
  width: 120px;
  height: 120px;
  flex: 0 0 120px;
  margin-right: 20px;
  background: url(/fe-web/img/icons/document.svg) no-repeat center center;

  &.document {
    background: url(/fe-web/img/icons/document.svg) no-repeat center center;
  }

  &.image {
    background: url(/fe-web/img/icons/image.svg) no-repeat center center;
  }

  &.video {
    background: url(/fe-web/img/icons/video.svg) no-repeat center center;
  }
}

.documentsRepositoryDetail-title {
  @include LibreBold;
  font-size: 18px;
  line-height: 1.1;
  margin-bottom: 15px;
  word-break: break-word;
}

.documentsRepositoryDetail-link {
  color: $blue;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity linear $default-timing-transition;

  &:after {
    content: '';
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background: url(/fe-web/img/icons/download.svg) no-repeat center center;
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
}