@import '../variables';
@import '../mixins';

.premium-form{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;


  .form-row{
    display: flex;
    gap:24px;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;

    @include max-device('tablet'){
      flex-direction: column;
    }
  }

  .form-col{
    width: 50%;

    @include max-device('tablet'){
      width: 100%;
    }
  }

  .form-col-full{
    width: 100%;
  }

  input,
  select{
    width: 100%;
    border-radius: 8px;
    border: 1px rgba(0, 0, 0, 0.10);
    background: rgba(57, 57, 57, 0.10);
    padding: 16px 24px;
    margin-top: 8px;
  }

  input[type='submit']{
    @include genericButton;
    width: fit-content;
    padding: 12px 16px;
    margin-left: auto;
  }


  input.is-invalid{
    border: 1px solid red;
  }

  select:focus-visible{
   outline: 1px solid red;
  }


  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("#{$icon-path}/arrow-down-blue.svg") no-repeat 97% center, rgba(57, 57, 57, 0.10);
  }

  label{
    @include LibreSemiBold;
    font-size: 16px;
    color: $dark-gray;
  }

  [disabled]{
    opacity: .5;
  }

  .calendar,
  .search {
    position: relative;

    img {
      width: 16px;
      position: absolute;
      top: 50%;
      right: 24px;
    }
  }

  .form-title{

    &.business:before  {
      @include icon-before('form-business');
      left: unset;
    }

    &.dealer:before,
    &.tires:before {
      @include icon-before('form-tires');
      left: unset;
    }

    &.brand:before  {
      @include icon-before('form-brand');
      left: unset;
    }

    &.replacement:before  {
      @include icon-before('form-replacement');
      left: unset;
    }

    &.machine:before  {
      @include icon-before('tractor');
      left: unset;
    }

    &.upload:before  {
      @include icon-before('upload-blue');
      left: unset;
    }

    h4{
      @include LibreBold;
      font-size: 24px;
      color: $blue;
      margin-left: 32px;
    }
  }

  input[type="file"] {
    display: none;
  }

  .next{
    @include genericButton();
    padding: 12px 16px;
    &:after{
      display: none;
    }
  }

  .prev {
    @include secondaryButton();
  }

  .iti{
    width: 100%;
  }


  label:has(.tooltip){
    display: flex;
    width: 100%;
  }

.tooltip{
  position: relative;
  margin-left: auto;

  .tooltiptext {
    @include LibreRegular();
    font-size: 10px;
    line-height: 12px;
    visibility: hidden;
    width: 203px;
    background: #E9ECEF;
    color: $dark-gray;
    border-radius: 6px;
    padding: 8px 12px;
    position: absolute;
    z-index: 1;
    bottom: 172%;
    right: 0;

    &:after{
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 15px;
      border-style: solid;
      border-color: #E9ECEF transparent transparent transparent;
    }

    img{
      margin-top: 5px;
    }
  }

  .tooltip-icon{
    width: 16px;
    height: 16px;
    vertical-align: text-top;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
}

}


.gigya-style-modern {
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: white;
}