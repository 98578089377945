@import '../../partials/variables';

.header-premium {
  background-color: transparent;
  position: relative;
  overflow: visible;
  font-size: 14px;

  padding: 20px 0;
  color: $blue;
  height: 90px;

  .logo-wrapper img {
    width: 200px;

    @include max-device('tablet') {
      width: 105px;
    }
  }

  .header-cnt,
  .menu-link-wrapper,
  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  .local-wrapper {
    display: none;

    .local-site {
      margin-right: 10px;
      color: $white;
      display: flex;
      align-items: center;
      gap: 8px;

      .country-flag{
        width: 32px;
        height: 32px;
        border-radius: 50px;
        border: 3px solid $white;

      }
    }

    @include max-device('tablet') {
      margin-top: 20px;
      align-items: baseline !important;
      flex-direction: column;
      gap: 10px;
    }
  }

  &.homepage {
    .local-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &.light {
    .logo-wrapper img{
      margin-top: auto;
    }

    .menu-link-wrapper {
      display: none;
    }
  }

  .menu-link-mobile{
    align-items: center;
    gap: 8px;
  }

  .hamburger {
    .close{
      display: none;
    }
  }

  .user {
    display: flex;
    background: $white;
    border-radius: 10px;
    padding: 12px 20px;
    color: $dark-gray;

    @include max-device('tablet'){
      background: rgba(255, 255, 255, 0.10);
      border: 1px solid rgba(255, 255, 255, 0.20);
      color: $white;
    }
  }

    .profile-arrow{
      margin-left: 24px;
      width: 16px;
      height: 16px;

      &.open{
        transform: rotate(180deg);
      }
      @include max-device('tablet'){
        margin-left: auto;
      }
    }

    .profile{
      margin-left: 25px;
      &:before{
        @include icon-before('profile-blue');
        width: 16px !important;
        height: 16px!important;

        @include max-device('tablet'){
          @include icon-before('profile');
        }
      }
    }

  .account{
    margin-left: 25px;
    &:before{
      @include icon-before('arrow-right-simple-blue');
      width: 16px !important;
      height: 16px!important;
      transform: rotate(180deg);

      @include max-device('tablet'){
        @include icon-before('arrow-right-simple-white');
      }
    }
  }

    .logout{
      margin-left: 25px;
      &:before{
        @include icon-before('logout');
        width: 16px !important;
        height: 16px!important;

        @include max-device('tablet'){
          @include icon-before('logout-yellow');
        }
      }
    }

    .inner-menu {
      display: none;
      width: 100%;
      position: absolute;
      left: 0;
      margin-top: 5px;
      top: 100%;
      padding: 10px;
      z-index: 10;
      background: $white;
      text-align: left;
      border-radius: 10px;

      @include max-device('tablet'){
        background: rgba(255, 255, 255, 0.10);
        border: 1px solid rgba(255, 255, 255, 0.20);
        text-align: left;
      }

      &.open {
        display: block;

        a {
          border-bottom: 1px solid $dark-gray;
          text-decoration: none;
          padding: 15px 0;
          margin-left: 32px;
          cursor: pointer;
          display: block;
          color:$dark-gray ;


          @include max-device('tablet'){
            color: $white;
            margin: 0 35px;
          }
          &:last-child {
            border: none;

            @include max-device('tablet'){
              color: $bright-gold;
            }
          }
        }
      }
    }

  .lang {
    @include genericButton;
    display: flex;
    align-items: center;
    color: $white;
    background: transparent;
    border: 1px solid $white;

    &:after {
      content: '';
      width: 12px;
      height: 8px;
      margin-left: 5px;
      background: url(/fe-web/img/icons/arrow-down-white.svg) no-repeat center center;
    }

    &:hover {
      .inner-menu {
        display: block;
      }
    }

    .inner-menu {
      display: none;
      width: 150px;
      position: absolute;
      left: 50%;
      margin-left: -75px;
      top: 20px;
      border: 1px solid black;
      padding: 4px 10px;
      z-index: 10;
      background: $white;
      text-align: center;
      border-radius: 10px;

      &:before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        top: -30px;
        left: 0;
        height: 30px;
      }

      .alt-locale {
        border-bottom: 1px solid $blue;
        text-decoration: none;
        cursor: pointer;
        display: block;
        padding: 8px 0;
        color: $blue;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .contact {
    text-decoration: underline;
    text-underline-offset: 4px;
    align-items: center;
    display: flex;
    color: $white;

    &:before{
      content: none;
    }
  }

  .communications-link{
    position: relative;
  }

  .communications-icon{
    width: 24px;
    height: 24px;
  }

  .unread-count{
    color: white;
    background: $bright-gold;
    border-radius: 50px;
    width: 14px;
    height: 14px;
    position: absolute;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    left: 12px;
    bottom: 12px;

  }

  @include max-device('tablet') {
    &.open {
      .menu-link-wrapper {
        padding:20px 30px;
        height: 100vh;
        display: flex;
        background: $premium-blue;
        width: 90%;
        left: 10%;
      }

      .hamburger .close{
        display: block;
        width: 42px;
        height: 42px;
      }

      .hamburger:has(.close){
        margin-left: auto;
      }
    }
    .logo-wrapper img {
      margin: 0;
    }
    .menu-link-wrapper {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      display: none;
      z-index: 100;
      width: 100%;
      top: 0;
      left: 0;

      .lang {
        width: 80%;
        text-align: center;
        display: block;
        border: 2px solid $blue;
        border-radius: 10px;
        padding: 10px;
        margin: 10px auto;
        font-size: 0;
        line-height: 0;

        .inner-menu {
          margin: -28px 0 12px;
          position: relative;
          display: block;
          border: none;
          width: 100%;
          left: 0;

          &:before {
            display: none;
          }

          .alt-locale {
            line-height: 14px;
            font-size: 14px;
          }
        }
      }

      .contact {
        text-align: left;
        display: flex;
        line-height: 16px;
      }

      .user {
        min-width: 290px;
        text-align: center;
        margin: 10px auto;
      }
    }
  }

  .link{
    margin-left: 0;
  }
}

.yxpBlock:has(.header-premium.open){
  padding: 0;
}