@use '../../partials/variables';


.yxpBlock:has(.footer-premium){
  padding: 0;
}

.footer-premium {
  padding: 30px 0;
  background: rgba(255, 255, 255, 0.10);

  backdrop-filter: blur(8px);
  color: $white;
  display: flex;
  justify-content: space-between;

  .footer-cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;

    @include max-device('tablet') {
      flex-direction: column;
    }
  }

  .link-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-device('tablet') {
      margin-top: 20px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 10px;
    }
  }

  a.link {
    color: $white;
    display: block;

    @include max-device('tablet') {
      margin-left: 0;
    }
  }
}
