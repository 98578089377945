@import '../../partials/variables';
@import '../../partials/mixins';

.fleet-card{
  min-width: 365px;
  padding: 24px;
  background: $lightest-gray;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);

  &:before{
    @include icon-before('tractor');
    left: unset;
  }

  @include max-device('desktop-plus'){
    max-width: 295px;
    min-width: 100%;
  }

  &__name{
    @include LibreBold();
    font-size: 18px;
    line-height: 24px;
    color: $blue;
    margin: 0 0 32px 32px;
  }

  &__info{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }


  &__row{
    display: flex;
    justify-content: space-between;
  }

  &__detail{
    @include LibreBold();
    font-size: 14px;
    line-height: 20px;
    color: $dark-gray;
  }

  &__spec{
    @include LibreRegular();
    font-size: 14px;
    line-height: 20px;
    color: $blue;
    border-radius: 4px;
    background: rgba(17, 46, 106, 0.10);
    padding: 2px 8px;
  }

  &__actions{
    @include LibreSemiBold();
    font-size: 16px;
    line-height: normal;
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 35px;

    img{
      width: 24px;
      height: 24px;
      vertical-align: bottom;
    }
  }

  &__edit a{
    color: $blue;
    text-decoration: none;
  }

  &__delete{
    color: $gold;
    cursor: pointer;
  }
}