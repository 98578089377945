@import '../partials/variables';
@import '../partials/mixins';

.cardWide-wrapper {
  .swiper-pagination {
    height: 10px;
    display: flex;
    align-items: flex-end;
    left: 0;
    z-index: 1;
    @include max-device('tablet') {
      left: 20px;
    }

    .swiper-pagination-bullet {
      width: 16px;
      height: 8px;
      border-radius: 100px;
      background: $blue;
      opacity: 0.5;
      margin-right: 5px;
      transition: all linear .2s;
      display: block;
      cursor: pointer;

      &-active {
        opacity: 1;
        width: 40px;
      }
    }
  }

  .swiper-nav-pag-cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 60px;
  }

  .swiper-navigation {
    display: flex;
    width: 75px;
    justify-content: space-between;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: url(/fe-web/img/icons/swiper-navigation.svg) no-repeat center center;
    width: 32px;
    height: 32px;

    &.swiper-button-disabled {
      opacity: 0.3;
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
  }

  .swiper-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 25px;
    width: 100%;

    @include max-device('tablet') {
      justify-content: flex-start;
    }

    @include min-device('tablet') {
      transform: translate3d(0, 0px, 0px) !important;
    }
  }

  @include min-device('tablet') {
    .swiper-nav-pag-cnt,
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

.cardWide-cnt {
  display: flex;
  flex: 0 49%;
  overflow: hidden;
  border-radius: 16px;
  background-color: $white;
  color: $black;
  text-decoration: none;

  @include max-device('tablet') {
    flex: 1 0 auto;
    margin-right: 20px;
    flex-direction: column;
  }

  &:first-of-type {
    box-shadow: 0 12px 18px rgb(22 22 22 / 15%);

    @include max-device('tablet') {
      box-shadow: none;
    }
  }
}

.cardWide-img {
  @include bgImg;
  flex-shrink: 0;
  width: 30%;

  @include max-device('tablet') {
    width: 100%;
    min-height: 130px;
  }
}

.cardWide-info {
  padding: 40px 30px;
}

.cardWide-title {
  @include LibreExtraBold;
  font-size: 21px;
  margin-bottom: 15px;
}

.cardWide-subtitle {
  @include lineClamp(2);
  color: $gray;
  margin-bottom: 25px;
}