@use '../partials/variables';

footer {
  padding: 40px 0;
  background-color: $blue;
  color: $white;
  display: flex;
  justify-content: space-between;

  .footer-cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;

    @include max-device('tablet') {
      flex-direction: column;
    }
  }

  .link-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-device('tablet') {
      margin-top: 20px;
      width: 100%
    }
  }

  a.link {
    color: $white;
    display: block;

    @include max-device('tablet') {
      margin-left: 0;
    }
  }
}
