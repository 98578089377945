@import '../../partials/variables';

.dashboard-premium{
  padding: 56px 20px;

  &__username{
    @include LibreRegular;
    font-size: 18px;
    color: $white;

  }

  //HIGHLIGHTS

  &__main-links-wrapper{
    display: flex;
    width: 100%;
    gap: 24px;
    margin: 24px 0;

    @include max-device('tablet'){
      flex-direction: column;
    }
  }

  &__main-link{
    padding: 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    width: 50%;

    &:first-child{
      background: $azure;
    }

    &:nth-child(2){
      background: $blue;
    }

    @include max-device('tablet'){
     width: 100%;
    }

    .secondary-button{
     visibility: hidden;

      @include max-device('desktop'){
       visibility: visible;
      }

      a{
        border: none;
      }
    }

    &:hover{
      .secondary-button {
        visibility: visible;
      }
    }
  }

  &__main-link-detail{
    img{
      width: 24px;
      height: 24px;
    }
  }

  &__main-link-name{
    @include LibreBold;
    font-size: 24px;
    color: $white;
    margin-bottom: 16px;
  }

  &__main-link-descr{
    @include LibreRegular;
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.65);

  }

  //BANNER
  .bannerFullWidth-wrapper .swiper-pagination{
    display: flex;
    align-items: flex-end;
    position: absolute;
    z-index: 2;
    justify-content: center;
    top: 95%;
    left: 50%;
    transform: translateX(-50%);
    //height: unset;

  }

    //NEWS AND CARDS
  &__boxes-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    margin-top: 24px;

    @include max-device('desktop'){
      flex-direction: column-reverse;
    }
  }

  //NEWS
  .newsList-wrapperPremium{
    width: 30%;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    min-height: 715px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;


    @include max-device('desktop'){
      width: 100%;
    }
    .generic-button{
      padding:12px 16px ;

      &:after{
        display: none;
      }
    }

    .cardTitle-wrapper{
      position: relative;
      color: $blue;

      &:before{
        @include icon-before('news');
        left: 0;

      }

      .card-title{
        margin-left: 30px;
      }
    }

    .newsList-info,
    .newsList-cardsWrapper{
      margin: 0;
    }

    .newsList-cardsWrapper > :not(:first-child){
      border-top: 1px solid #F1F1F1;
    }

    .newsCard-wrapper{
      border-top: none;
      filter: grayscale(100%);
      cursor: pointer;

      &:hover{
        filter: none;
      }
    }
  }

  //CARDS
  .card-grid{
    &__wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 70%;
      gap: 24px;
      margin-top: 0;

      @include max-device('desktop'){
        width: 100%;
      }
    }

    &__item{
      width: 48%;

      a{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
      }

      @include max-device('desktop'){
        width: 100%;
      }
    }

    &__title{
      margin: 0 0 8px 0;

      img{
        height: 24px;
        width: 24px;
        vertical-align: bottom;
      }
    }
  }
}

