@import '../partials/variables';
@import '../partials/mixins';

.splashPage {
  padding: 50px 20px 80px;
  border-top: 1px solid $another-light-gray;

  .content-wrapper-cnt {
    display: flex;
    flex-direction: column;
  }

  &__two-cols{
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-device('medium-desktop') {
      flex-direction: column;
    }
  }

  &__accordion-wrapper{
    flex: 0 1 60%;
    margin-right: 5%;

    @include max-device('tablet') {
      margin-right: 0;
      flex: 1;
    }
  }

  &__title,
  &__subtitle {
    line-height: 32px;
  }

  &__title {
    @include LibreExtraBold;
    font-size: 28px;
    margin-bottom: 5px;
  }

  &__subtitle {
    @include LibreLight;
    font-size: 16px;
    margin-bottom: 40px;
  }

  &__loghi{
    display: flex;
    gap: 10px;

    img{
      max-width: 98px;

      @include max-device('tablet') {
        max-width: 75px;
      }
    }

    @include max-device('medium-desktop') {
      margin-bottom: 30px;
    }
  }

  &__text-accordion{
    @include LibreBold;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  &__accordion-item {
    margin-bottom: 10px;
    border: 1px solid $another-light-gray;
    position: relative;

    &:after {
      content: '';
      width: 12px;
      height: 8px;
      position: absolute;
      top: 25px;
      right: 20px;
      background: url(/fe-web/img/icons/arrow-down-blue.svg) no-repeat center center;
      transition: transform ease-in .3s;
      transform: rotate(180deg);
    }

    &.closed {
      .splashPage__accordion-body {
        height: 0 !important;
      }

      &:after {
        transform: rotate(0deg);
      }
    }
  }

  &__accordion-header {
    @include LibreBold;
    padding: 20px;
    cursor: pointer;
  }

  &__accordion-body {
    overflow: hidden;
    transition: height ease-in .3s;
  }

  &__accordion-content {
    font-size: 14px;
    padding: 20px;
    border-top: 1px solid $another-light-gray;

    &[data-count="twoCols"] {
      column-count: 2;
    }
  }

  &__accordion-list {
    margin-bottom: 15px;

    &:hover {
      @include LibreSemiBold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__accordion-link {
    color: $black;
    text-decoration: none;

    &:hover {
      @include LibreBold;
      text-decoration: underline;
    }
  }

  &__image {
    padding-top: 100px;

    @include max-device('medium-desktop') {
      padding-top: 50px;
    }
  }

  @include max-device('tablet') {
    padding: 30px 0;
  }
}
