@import '../../partials/variables';
@import '../../partials/mixins';


.categoryDetailPremium{

  &__wrapper{
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: $white;
    align-items: center;
    margin: 14px auto 132px;
    padding: 36px 56px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(241, 241, 241, 0.1);
    backdrop-filter: blur(8px);

    @include max-device('tablet') {
      padding: 36px 20px;
      max-width: 350px;
    }
  }

  &__category{
    text-align: center;
    margin-bottom: 32px;
  }

  &__description{
    @include LibreRegular;
    font-size: 16px;
    line-height: 24px;
  }

  h2{
    text-align: center;
    margin-bottom: 32px;
  }

  &__files-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    
    @include max-device('medium-desktop') {
      justify-content: center;
    }
  }

  &__doc-card {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 550px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: linear-gradient(0deg, rgba(216, 216, 216, 0.30) 0%, rgba(216, 216, 216, 0.30) 100%), #FFF;
    background-blend-mode: multiply, normal;

    @include max-device('tablet') {
      width: 315px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__doc-info{
    display: flex;
    align-items: center;
    gap: 16px;
  }

    //&__doc-icon{
    //  width: 40px;
    //  height: 40px;
    //}

  &__doc-icon {
    width: 40px;
    height: 40px;
    //flex: 0 0 120px;
    //margin-right: 20px;
    background: url(/fe-web/img/icons/document-round-blue.svg) no-repeat center center;

    &.document {
      background: url(/fe-web/img/icons/document-round-blue.svg) no-repeat center center;
    }

    &.image {
      background: url(/fe-web/img/icons/img-placeholder.svg) no-repeat center center;
    }

    &.video {
      background: url(/fe-web/img/icons/table.svg) no-repeat center center;
    }
  }



  &__doc-name{
      @include LibreRegular;
      font-size: 14px;
      color: $dark-gray;
    }

  }


