@import "../../partials/_variables";

.contact-us{
  &__form-wrapper{
    display: flex;
    flex-direction: column;
    color: $dark-gray;
    align-items: center;
    margin: 14px auto 32px;
    padding: 56px 56px 32px 56px;
    border-radius: 8px;
    border: 1px rgba(255, 255, 255, 0.20);
    background:$lightest-gray;
    backdrop-filter: blur(8px);

    @include max-device('tablet'){
      margin: 14px 20px 132px;
      padding: 56px 20px;
    }

    .form-col{
      width: 100%;
    }

    textarea{
      @include LibreRegular();
      width: 100%;
      height: 184px;
      margin-top: 8px;
      padding: 16px 24px;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      background: rgba(57, 57, 57, 0.10);
    }

    .form-cta{
      text-align: center;

      input{
        margin-top: 0;
      }
    }
  }

  &__form-wrapper.thankyou-contact{
    gap: 32px;
    text-align: center;

    img{
      width: 48px;
    }
  }

  &__contacts-wrapper{
    display: flex;
    flex-direction: column;
    color: $white;
    align-items: center;
    margin: 0 auto 90px;
    padding: 36px 56px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(241, 241, 241, 0.1);
    backdrop-filter: blur(8px);

    @include max-device('tablet') {
      padding: 36px 20px;
      max-width: 350px;
    }
  }

  &__business-cards-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 32px;

    @include max-device('desktop-plus') {
      flex-direction: column;
      align-items: center;
    }

  }

  &__business-card{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    border-radius: 8px;
    background: $white;
    color: $blue;
    width: 555px;
    @include max-device('tablet'){
      width: 310px;
      padding: 16px;
    }
  }

  &__business-name{
    @include LibreBold();
    font-size: 16px;
    line-height: 20px;
  }

  &__business-address{
    @include LibreRegular();
    color: rgba(57, 57, 57, 0.80);
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: column;

    img{
      width: 24px;
      height: 24px;
      margin-bottom: 8px;
    }
  }

  &__business-contacts{
    @include LibreSemiBold();
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
    line-height: 16px;
    background: rgba(17, 46, 106, 0.10);
    padding: 8px;
    margin-top: 32px;

    @include max-device('tablet'){
      min-height: 136px;
      justify-content: center;
      gap: 22px;
    }
  }

  &__business-phone{
    position: relative;
    &:before{
      @include icon-before('phone');
      left: 0;

      @include max-device('tablet'){
        top: -20px;
      }
    }

    span{
      margin-left: 32px;
      vertical-align: sub;

      @include max-device('tablet'){
        margin-left: 0;
      }
    }

  }

  &__business-email{
    position: relative;
    &:before{
      @include icon-before('email');
      left: 0;

      @include max-device('tablet'){
        top: -20px;
      }
    }

    span{
      margin-left: 32px;
      vertical-align: sub;

      @include max-device('tablet'){
        margin-left: 0;
      }
    }
  }
}