@import '../partials/_variables';
@import '../partials/_fonts';
@import '../partials/_mixins';

*,
*:after,
*:before {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
  //table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

body {
  @include LibreRegular;
  line-height: 1;
}

img {
  width: 100%;
}

b,
strong {
  @include LibreSemiBold;
}

.content-wrapper-cnt {
  @include contentWrapperCnt;
}

.center {
  text-align: center;
}

h1{
  @include LibreBold;
}

.link {
  margin-left: 35px;
  position: relative;
  cursor: pointer;
}

.button {
  @include genericButton;
}

.card-link {
  @include cardLink;
}


//news - card
.cardTitle-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  @include LibreBold;
  font-size: 21px;
  padding-right: 20px;
}

input[type="checkbox"]{
  width: 16px;
  height: 16px;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid $gold;
  border-radius: 4px;
  margin: 0 5px 0 0;
  flex: 0 0 auto;

  &:checked {
    background-image: url(/fe-web/img/icons/checkbox.svg);
    background-position: center;
  }
}

.no-padding.no-padding {
  padding: 0;
}

.swiper-container {
  overflow: hidden;
}

.yxpType_VIDEO.yxpType_VIDEO {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  max-width: 569px;

  @include max-device('tablet') {
    margin-top: 42px;
    width: 100%;
  }
}

.yxpType_VIDEO.yxpType_VIDEO.centered{
  display: block;
  margin: 0 auto 50px;
  max-width: 100%;
}

.top-padding.top-padding {
  padding: 55px 0 0;

  @include max-device('desktop') {
    padding: 10px 0 0;
  }
}

.half-padding.half-padding,
.yxpBlock.half-padding.half-padding {
  padding: 32px 64px;
}

.bg-lightestgray {
  background-color: $lightest-gray;
}

.bg-white {
  background-color: $white;
}

.round {
  border-radius: 8px;
}

.mobile {
  display: none;

  @include max-device('tablet') {
    display: inline-flex;
  }
}

.desktop {
  display: inline-flex;

  @include max-device('tablet') {
    display: none;
  }
}

.generic-button{
  @include genericButton;
}

.primary-button{
  @include genericButton;
  padding: 12px 16px;

  &:after{
    display: none;
  }
}

.secondary-button.secondary-button .yxpLink,
.secondary-button.secondary-button a{
  @include secondaryButton;
}

.transparent-button.transparent-button .yxpLink{
  @include secondaryButton;
  color: $white;
  background: transparent;
  border: 1px solid $white;
}

.link-button {
  position: relative;
  a{
    @include LibreBold;
    color: $white;
    background: transparent;
    font-size: 14px;
    text-decoration: none;
    margin-left: 20px;
  }

  &:before {
    @include icon-before('arrow-right-white');
    transform: rotate(180deg);
    width: 16px;
    height: 16px;
    left: 0;
  }
}

.download-button{

  a{
    @include secondaryButton;
    padding: 12px 16px 12px 41px !important;
    border: none;
    &:before {
      @include icon-before('download');
      width: 16px;
      height: 16px;
      left: 16px;
    }
  }
}

.download-button-blue{
  a{
    @include genericButton;
    padding: 12px 16px 12px 41px !important;
    border: none;
    &:after {
      background: url("#{$icon-path}/download-white.svg") no-repeat center center;
      width: 16px;
      height: 16px;
      left: 16px;
    }
  }
}

.upload-button{
    @include genericButton;
    padding: 12px 41px 12px 16px !important;
    border: none;
    color: $white !important;
    &:after {
      background: url("#{$icon-path}/upload.svg") no-repeat center center;
      width: 16px;
      height: 16px;

    }

}

.arrow-button {
  @include secondaryButton;

  border: none;
  &:after{
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: auto;
    right: 18px;
    background: url("#{$icon-path}/arrow-link.svg") no-repeat center center;
  }
}


.width-800 {
  max-width: 800px;
  margin: auto;
}

.width-1260 {
  max-width: 1260px;
  margin: auto;
}

.double-column{
  padding: 24px 24px 36px 24px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  color: $white;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(8px);
  line-height: 24px;

  .yxpRow{
    gap: 48px;

    @include max-device('tablet') {
      flex-direction: column;
    }

    .yxp-cols-6{
      @include max-device('tablet') {
        width: 100%;
      }
    }
  }
}

.text-white{
  color: $white;
}

.join-us-banner{
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px auto;
  padding: 24px 0;
  text-align: center;
  font-size: 18px;

}

.hidden{
  display: none !important;
}

.loading{
  @include loading();
}