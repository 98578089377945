@import '../../partials/variables';
@import '../../partials/mixins';


.professionalTrainingPremium{

  &__wrapper{
    display: flex;
    flex-direction: column;
    color: $white;
    align-items: center;
    margin: 14px auto 132px;
    padding: 36px 56px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(241, 241, 241, 0.1);
    backdrop-filter: blur(8px);

    @include max-device('tablet') {
      padding: 36px 20px;
      max-width: 350px;
    }
  }


  &__list{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 32px;
    justify-content: center;
    //@include max-device('tablet') {
    //  flex-direction: column-reverse;
    //}
  }

}