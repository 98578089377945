@import '../partials/variables';
@import '../partials/mixins';

.gallery {
  position: relative;
  overflow: hidden;

  .swiper-slide {
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
    background-repeat: no-repeat;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.25);
      opacity: 0.25;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: url(/fe-web/img/icons/swiper-navigation.svg) no-repeat center center;
    width: 32px;
    height: 32px;
    top: auto;
    margin-top: 0;

    &.swiper-button-disabled {
      opacity: 0.3;
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-prev {
    transform: rotate(180deg);
  }

  &[data-slider-config="classic"] {
    .swiper-slide {
      height: 278px;

      @include max-device('tablet') {
        height: 193px;
      }
    }

    .swiper-pagination {
      display: none;
    }

    .swiper-navigation {
      position: absolute;
      width: 75px;
      display: flex;
      bottom: 15px;
      right: 30px;
      height: 32px;
    }

    @include max-device('tablet') {
      margin: auto 16px;
    }
  }

  &[data-slider-config="carousel"] {
    height: 129px;
    width: 100%;
    padding: 0 6%;

    .swiper-button-prev,
    .swiper-button-next {
      width: 6%;
      background-color: $white;
      height: 100%;
      top: 0;

      &.swiper-button-disabled {
        opacity: 1;
        background: url(/fe-web/img/icons/swiper-navigation-disabled.svg) no-repeat center center;

        &.swiper-button-prev {
          transform: none;
        }

        &.swiper-button-next {
          transform: rotate(180deg);
        }
      }
    }

    .swiper-pagination {
      position: relative;
    }

    @include max-device('tablet') {
      height: 220px;
      display: flex;
      flex-direction: column;
      padding: 0 0 0 16px;

      &.swiper {
        @include swiper;

        .swiper-controls {
          display: flex;
          justify-content: space-between;
          position: relative;
          align-items: center;
          margin-top: 30px;
          padding-right: 16px;
        }

        .swiper-button-prev,
        .swiper-button-next {
          position: relative;
          left: 0;

          &.desktop {
            pointer-events: none;
          }
        }

        .swiper-button-prev.swiper-button-prev {
          transform: rotate(180deg);
        }

        .swiper-button-next.swiper-button-next {
          &.swiper-button-disabled {
            transform: none;
          }
        }
      }
    }
  }
}