@import '../../partials/variables';
@import '../../partials/mixins';

.newsListing-wrapper-premium {
  min-height: calc(100vh - 164px);
}

.newsListing-cardsWrapper-premium {
  margin: 14px auto 132px;
  padding: 36px 56px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: rgba(241, 241, 241, 0.10);
  backdrop-filter: blur(8px);

  @include max-device('tablet') {
    padding: 36px 20px;
    max-width: 350px;
  }

    &__list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 32px;

      @include max-device('medium-desktop') {
        justify-content: center;
      }
  }
}

//CARD

.newsListing-cardPremium {
  //flex: 0 1 49%;
  width: 572px;
  height: 189px;
  display: flex;
  border-radius: 8px;
  padding: 20px;
  background-color: $white;
  overflow: hidden;

  @include max-device('tablet') {
    width: 315px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding-left:16px;
    width: 409px;

    @include max-device('tablet') {
      width: 152px;
      flex-direction: column;
    }
  }

  &__subinfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    flex: 0 0 111px;
    border-radius: 8px;
  }

  &__title {
    @include LibreBold;
    @include lineClamp(2);
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    height:40px;
  }

  &__subtitle {
    @include lineClamp(3);
    font-size: 14px;
    color: $gray;
    height:48px;
    line-height: 16px;
    margin-bottom: 16px;

    @include max-device('tablet') {
      @include lineClamp(4);
     height: 64px;
    }
  }

  &__date {
    @include LibreBold;
    color: $blue;
    font-size: 12px;
  }

  &__link {
    @include LibreBold;
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
    color: $blue;
    border-bottom: 1px solid $blue;
    align-self: flex-start;
    transition: opacity linear $default-timing-transition;

    &:hover {
      opacity: 0.8;
    }
  }
}





